import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_EIGHT, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ELEVEN, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FIVE, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FOUR, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_NINE, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ONE, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SEVEN, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SIX, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TEN, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_THREE, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TWO } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { bookmarkParagraphOptions, bookmarkParagraphOptions2, bookmarkStyle, bookmarkStyle2, grayTitlesMain } from '../styles/elsterDocStyles';
const generalCompanyInformation = data => {
  const {
    t
  } = i18n;
  const {
    createBookmark,
    createTextRunInParagraph
  } = elsterDocxUtils;
  const mainParagraphOptions = {
    spacing: {
      before: 300,
      after: 200
    }
  };
  return [createBookmark(t('tk_ginfo_company_1'), 'anchor_tk_ginfo_company_1', bookmarkStyle, bookmarkParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ONE), createTextRunInParagraph(t('business_address'), grayTitlesMain, mainParagraphOptions), createTextRunInParagraph(t('address_at_home_abroad'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TWO), createTextRunInParagraph(t('legal_representative'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_THREE), createTextRunInParagraph(t('information_on_tax_exemption'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FOUR), createTextRunInParagraph(t('information_on_the_distribution'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FIVE), createTextRunInParagraph(t('information_on_affiliated_companies'), grayTitlesMain, mainParagraphOptions), createTextRunInParagraph(t('gci_please_note_that_only'), grayTitlesMain, mainParagraphOptions), ...data.claim_group_affiliated_companies.flatMap(affiliated_company => {
    return [generalTable(affiliated_company, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SIX), emptyLine];
  }), createBookmark(t('tk_info_totwage_enterprise_finy'), 'anchor_tk_info_totwage_enterprise_finy', bookmarkStyle2, bookmarkParagraphOptions2), generalTable(data.claim_group_wage_cost, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SEVEN), createBookmark(t('tk_information_wage_costs_eng_res'), 'anchor_tk_information_wage_costs_eng_res', bookmarkStyle2, bookmarkParagraphOptions2), generalTable(data.claim_group_wage_cost, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_EIGHT), createTextRunInParagraph(t('additional_information_for_partnership'), grayTitlesMain, mainParagraphOptions), createTextRunInParagraph(t('additional_information_for_partnership_subtitle'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_NINE), createTextRunInParagraph(t('details_of_the_parties_involved_in_the_assessment'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TEN), createTextRunInParagraph(t('information_on_breakdown_11'), grayTitlesMain, mainParagraphOptions), generalTable(data, GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ELEVEN), emptyLine];
};
export default generalCompanyInformation;