import { elsterDocxUtils, Table, TableRow, VerticalAlign } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { headerColumnWidth, contentColumnWidth, borders, tableMargins } from '../styles/elsterDocStyles';
const generalTable = (data, tableInstructions) => {
  const {
    t
  } = i18n;
  const {
    createParagraph,
    createTableCell
  } = elsterDocxUtils;
  const cellMainOptions = {
    borders: borders,
    verticalAlign: VerticalAlign.CENTER
  };
  const columnWidth = (headerColumnWidth + contentColumnWidth) / 2;
  const createLeftCell = tableInstruction => {
    return createTableCell(cellMainOptions, headerColumnWidth, [createParagraph(t(tableInstruction.translationKey), {
      style: 'mainText'
    })]);
  };
  const createRightCell = tableInstruction => {
    var _data$tableInstructio;
    return createTableCell(cellMainOptions, headerColumnWidth, [createParagraph(tableInstruction.shouldTranslateRightCell ? t(data[tableInstruction.dataKey]) : (_data$tableInstructio = data[tableInstruction.dataKey]) === null || _data$tableInstructio === void 0 ? void 0 : _data$tableInstructio.toString(), {
      style: 'mainText'
    })]);
  };
  const createRow = tableInstruction => [createLeftCell(tableInstruction), createRightCell(tableInstruction)];
  const tableRows = tableInstructions.map(tableInstruction => {
    return new TableRow({
      children: createRow(tableInstruction)
    });
  });
  const table = new Table({
    columnWidths: [columnWidth, columnWidth],
    margins: {
      ...tableMargins
    },
    rows: tableRows
  });
  return table;
};
export default generalTable;