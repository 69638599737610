import { Document, elsterDocxUtils } from './utils/elster_docx_utils';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { createElements } from './create_elements';
import generateFootersSetting from './settings/generateFootersSetting';
import generateHeadersSetting from './settings/generateHeadersSetting';
import generateDocumentProperties from './settings/generateDocumentProperties';
import { mainTextStyles } from './styles/elsterDocStyles';
export const generateElsterDocument = async _ref => {
  let {
    data
  } = _ref;
  const fileNameDate = createTimezonedDate(new Date()).toCustomLocaleDateString(true);
  const {
    createDocument
  } = elsterDocxUtils;
  const getDocumentName = () => {
    return "".concat(fileNameDate, "_").concat(data.company_name, "_elster_document").concat(data.financial_year, ".docx");
  };

  // Create the document
  const doc = new Document({
    styles: {
      paragraphStyles: [{
        id: 'mainText',
        name: 'mainText',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          ...mainTextStyles
        }
      }]
    },
    sections: [{
      properties: generateDocumentProperties(),
      headers: generateHeadersSetting(),
      footers: generateFootersSetting(),
      children: createElements(data)
    }]
  });
  createDocument(getDocumentName(), doc);
};