import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { FURTHER_REQUIREMENTS_ONE } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { bookmarkParagraphOptions, bookmarkStyle2 } from '../styles/elsterDocStyles';
const section4 = data => {
  const {
    t
  } = i18n;
  const {
    createBookmark
  } = elsterDocxUtils;
  return [createBookmark(t('tk_freq_4'), 'anchor_tk_freq_4', bookmarkStyle2, bookmarkParagraphOptions), generalTable(data, FURTHER_REQUIREMENTS_ONE), emptyLine];
};
export default section4;