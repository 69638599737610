import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { PARTICIPATION_ONE, PARTICIPATION_TWO, POWER_OF_ATTORNEY_ONE, POWER_OF_ATTORNEY_TWO } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { bookmarkParagraphOptions, bookmarkStyle2 } from '../styles/elsterDocStyles';
const section78 = data => {
  const {
    t
  } = i18n;
  const {
    createBookmark
  } = elsterDocxUtils;
  return [createBookmark(t('tk_participation_7'), 'anchor_tk_participation_7', bookmarkStyle2, bookmarkParagraphOptions), generalTable(data, PARTICIPATION_ONE), emptyLine, generalTable(data, PARTICIPATION_TWO), emptyLine, createBookmark(t('tk_power_of_attorney_8'), 'anchor_tk_power_of_attorney_8', bookmarkStyle2, bookmarkParagraphOptions), generalTable(data, POWER_OF_ATTORNEY_ONE), emptyLine, generalTable(data, POWER_OF_ATTORNEY_TWO), emptyLine];
};
export default section78;