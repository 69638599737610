export const APPLICATION_FOR_RESEARCH_TITLE = 'application_for_research_allowance';
export const APPLICATION_FOR_RESEARCH_BODY = 'application_for_research_allowance_body';
export const APPLICATION_ALLOWANCE_TABLE_INSTRUCTIONS = [{
  translationKey: 'for_the_financial_year',
  dataKey: 'claim_group_financial_year',
  shouldTranslateRightCell: false
}, {
  translationKey: 'tax_reference',
  dataKey: 'company_utr',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ONE = [{
  translationKey: 'company_name',
  dataKey: 'company_name',
  shouldTranslateRightCell: false
}, {
  translationKey: 'location',
  dataKey: 'company_location',
  shouldTranslateRightCell: false
}, {
  translationKey: 'legal_form',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'industry',
  dataKey: 'company_industry',
  shouldTranslateRightCell: false
}, {
  translationKey: 'tax_group',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TWO = [{
  translationKey: 'street',
  dataKey: 'company_street',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'company_house_number',
  shouldTranslateRightCell: false
}, {
  translationKey: 'postal_code',
  dataKey: 'company_postcode',
  shouldTranslateRightCell: false
}, {
  translationKey: 'place',
  dataKey: 'company_location',
  shouldTranslateRightCell: false
}, {
  translationKey: 'country_in_case_of_foreign_countries',
  dataKey: 'company_country',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_THREE = [{
  translationKey: 'salutation',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'forename',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FOUR = [{
  translationKey: 'gci_4_the_company_exempt',
  dataKey: 'company_tax_exemption',
  shouldTranslateRightCell: true
}, {
  translationKey: 'gci_4_the_research_and_dev',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_FIVE = [{
  translationKey: 'gci_5_it_is_an_affiliated',
  dataKey: 'company_family_group_name',
  shouldTranslateRightCell: true
}, {
  translationKey: 'gci_5_has_a_company_affiliated',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_5_will_the_max_eligible_ex',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_5_maximum_eligible_ass',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SIX = [{
  translationKey: 'gci_6_tax_number',
  dataKey: 'tax_number',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_6_name_of_the_aff',
  dataKey: 'name',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_6_if_available',
  dataKey: 'date_of_application',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_SEVEN = [{
  translationKey: 'gci_7_total_number_of',
  dataKey: 'num_employees_in_enterprise_income_tax_is_deducted',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_7_number_of_employees',
  dataKey: 'gross_wages_of_employees_for_whom_income_tax_is_deducted',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_7_gross_wages_granted_deduction',
  dataKey: 'num_employees_with_no_tax_deduction_due_to_dta',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_7_gross_wages_granted_pursuant',
  dataKey: 'gross_wages_employees_with_no_income_tax_due_to_dta',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_7_expenditure',
  dataKey: 'expenditure_to_secure_the_future_of_employees',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_EIGHT = [{
  translationKey: 'gci_8_total_number',
  dataKey: 'num_employees_tax_deducted_by_full_time_eq',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_8_number_of_employees',
  dataKey: 'gross_wages_employees_with_no_income_tax_in_rd_due_to_dta',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_8_gross_wages_deducted',
  dataKey: 'num_employees_with_no_tax_deduction_due_to_dta_by_full_time_eq',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_8_gross_wages_pursuant',
  dataKey: 'gross_wages_employees_in_rd_projects_with_no_tax_deduct',
  shouldTranslateRightCell: false
}, {
  translationKey: 'gci_8_expenditure',
  dataKey: 'expenditure_to_safeguard_the_future_of_employees_engaged_in_r_d',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_NINE = [{
  translationKey: 'salutation',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name_line_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name_line_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'street',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'postal_code',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'place',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_TEN = [{
  translationKey: 'gci_10_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'forename',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'street',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'post_code',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'place',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'tin',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'identificaiton',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'type_of_participant',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const GENERAL_COMPANY_INFORMATION_TABLE_INSTRUCTIONS_ELEVEN = [{
  translationKey: 'gci_11_capital',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_ONE = [{
  translationKey: 'iorado_p_1_project_id',
  dataKey: 'application_number',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_tax_number_of',
  dataKey: 'company_utr',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_name_of_researcher',
  dataKey: 'full_project_name',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_art',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_cooperation_projects',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_start_of_work',
  dataKey: 'project_start_date',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_the_decision_of_the',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_the_information_declared',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_1_further_has',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_TWO = [{
  translationKey: 'iorado_p_2_the_research_and_development',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_THREE = [{
  translationKey: 'iorado_p_3_1',
  dataKey: 'iihrde_number_of_times_associated',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_2',
  dataKey: 'iihrde_corresponds_by_head',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_3',
  dataKey: 'iihrde_proportion_of_gross_wages_with_wage_tax',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_4',
  dataKey: 'iihrde_gross_salary',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_5',
  dataKey: 'iihrde_gs_received_by_employees_not_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_6',
  dataKey: 'iihrde_gs_received_by_employees_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_7',
  dataKey: 'iihrde_proportion_of_gross_wages_no_wage_tax',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_8',
  dataKey: 'iihrde_pgs_not_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_9',
  dataKey: 'iihrde_pgs_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_10',
  dataKey: 'iihrde_prorota_expenditure',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_11',
  dataKey: 'iihrde_prorota_expenditure_not_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_3_12',
  dataKey: 'iihrde_prorota_expenditure_in_20_26',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_FOUR = [{
  translationKey: 'iorado_p_4_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_4_2',
  dataKey: 'ocsp_working_hours',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_4_3',
  dataKey: 'ocsp_working_hours_not_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_4_4',
  dataKey: 'ocsp_working_hours_in_20_26',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_FIVE = [
// shareholders
{
  translationKey: 'iorado_p_5_1',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_2',
  dataKey: 'ocsce_is_civil_agreement',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_3',
  dataKey: 'ocsce_total_remun_for_shareholders',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_4',
  dataKey: 'ocsce_total_remun_for_project',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_5',
  dataKey: 'ocsce_total_working_hours_shareholder_on_project',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_6',
  dataKey: 'ocsce_total_wh_shareholder_not_in_20_26',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_5_7',
  dataKey: 'ocsce_total_wh_shareholder_in_20_26',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_SIX = [
// only for contract research 
{
  translationKey: 'iorado_p_6_1',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_6_2',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_6_3',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_6_4',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_6_5',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_SEVEN = [
// Daten des Auftragnehmers || contractors_data
{
  translationKey: 'iorado_p_7_1',
  dataKey: 'default_field_consent_is_granted',
  shouldTranslateRightCell: true
}, {
  translationKey: 'iorado_p_7_2',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'street',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'postal_code',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'place',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}, {
  translationKey: 'state',
  dataKey: 'DEFINE_LATER',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_EIGHT = [
// Only when receiving or applying for other subsidies  
{
  translationKey: 'iorado_p_8_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_8_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_8_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_NINE = [
// Only for cooperation projects
{
  translationKey: 'iorado_p_9_1',
  dataKey: 'ic_rd_project_will_carried_with_affiliated_companies',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_9_2',
  dataKey: 'ic_prop_carried_in_affiliated_companies',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_9_3',
  dataKey: 'ic_rd_project_is_carried_with_taxexempt_research_institutions',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_9_4',
  dataKey: 'ic_prop_carried_in_taxexempt_inst',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_9_5',
  dataKey: 'ic_rd_project_will_carried_with_other_taxable_comapnies',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_9_6',
  dataKey: 'ic_prop_carried_in_other_taxable_companies',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_TEN = [
// Determination of the assessment basis for eligible expenses incurred before 01.07.202 
{
  translationKey: 'iorado_p_10_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_10_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_10_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const IORADO_PROJECTS_ELEVEN = [
// Ermittlung der Bemessungsgrundlage für förderfähige Aufwendungen, die nach dem 30.06.2020 entstanden sind  
{
  translationKey: 'iorado_p_11_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_11_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'iorado_p_11_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DMVER_ONE = [
// Deminis Verodnung
{
  translationKey: 'dmver_1_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DMVER_TWO = [
// Erklärung der De-minimis-Beihilfen
{
  translationKey: 'dmver_2_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dmver_2_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dmver_2_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DMVER_THREE = [
// Erklärung der DAWI-de-minimis-Beihilfen
{
  translationKey: 'dmver_3_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dmver_3_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dmver_3_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const FURTHER_REQUIREMENTS_ONE = [
// Further requirements under European state aid law
{
  translationKey: 'fr_1_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'fr_1_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'fr_1_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_ONE = [
// Förderfähige Aufwendungen aus eigenbetrieblichen Forschungs- und Entwicklungsvorhaben: Arbeitslöhne
{
  translationKey: 'dos_1_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_TWO = [
// Eligible expenses from in-house research and development projects: Own contributions by a sole proprietor or by partners of a co-entrepreneurship 
{
  translationKey: 'dos_2_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_2_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_2_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_2_4',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_2_5',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_THREE = [
// Eligible expenditure from contract research
{
  translationKey: 'dos_3_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_3_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_FOUR = [
// Subtotal of eligible expenses incurred after 01.01.2020 and before 01.07.2020
{
  translationKey: 'dos_4_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_FIVE = [
// Eligible expenditure from in-house research and development projects: Wages
{
  translationKey: 'dos_5_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_SIX = [
// Eligible expenses from in-house research and development projects: Own contributions by a sole proprietor or by partners of a co-entrepreneurship
{
  translationKey: 'dos_6_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_6_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_6_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_6_4',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_6_5',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_SEVEN = [
// Förderfähige Aufwendungen aus Auftragsforschung
{
  translationKey: 'dos_7_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_7_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_EIGHT = [
// Subtotal of eligible expenses incurred after 30.06.2020
{
  translationKey: 'dos_8_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_NINE = [
// 1.3 Determination of the eligible assessment basis for the respective financial year
{
  translationKey: 'dos_9_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_9_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_9_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_9_4',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_TEN = [
// Determination of the research allowance applied for 
{
  translationKey: 'dos_10_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_ELEVEN = [
// De minimis examination for eligible expenses from own contributions
{
  translationKey: 'dos_11_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_11_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_11_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const DETERMINATION_OF_ASSESSMENT_TWELVE = [
// Determination of the aid value without limitation to a maximum basis of assessment
{
  translationKey: 'dos_12_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_12_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_12_3',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_12_4',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'dos_12_5',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const FINAL_DECLARATION_ONE = [
// Final Declaration 6, table 1
{
  translationKey: 'final_declaration_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'final_declaration_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const PARTICIPATION_ONE = [
// Participation in the preparation of this application 7, table 1
{
  translationKey: 'participation_1_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const PARTICIPATION_TWO = [
// Participation in the preparation of this application 7, table 2
{
  translationKey: 'name',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'forename',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'street',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'postal_code',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'ort',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'phone_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'adiitional_information',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'mandator_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'editor_identifier',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const POWER_OF_ATTORNEY_ONE = [
// Power of Attorney 8, table 1
{
  translationKey: 'power_of_attorney_1_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];
export const POWER_OF_ATTORNEY_TWO = [
// Power of Attorney 8, table 2
{
  translationKey: 'salutation',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name_line_1',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'name_line_2',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'street',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'house_number',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'postal_code',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}, {
  translationKey: 'place',
  dataKey: 'NOT_EXIST',
  shouldTranslateRightCell: false
}];