import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { DMVER_ONE, DMVER_THREE, DMVER_TWO } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { bookmarkParagraphOptions, bookmarkStyle2, grayTitlesMain } from '../styles/elsterDocStyles';
const section3 = data => {
  const {
    t
  } = i18n;
  const {
    createBookmark,
    createTextRunInParagraph
  } = elsterDocxUtils;
  const mainParagraphOptions = {
    spacing: {
      before: 300,
      after: 200
    }
  };
  return [createBookmark(t('tk_dem_3'), 'anchor_tk_dem_3', bookmarkStyle2, bookmarkParagraphOptions), createTextRunInParagraph(t('tk_deminimis_section3_3_subtitle'), grayTitlesMain, mainParagraphOptions), generalTable(data, DMVER_ONE), createTextRunInParagraph(t('dmver_erklarung_2'), grayTitlesMain, mainParagraphOptions), generalTable(data, DMVER_TWO), createTextRunInParagraph(t('dmver_erklarung_3'), grayTitlesMain, mainParagraphOptions), generalTable(data, DMVER_THREE), emptyLine];
};
export default section3;