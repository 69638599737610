import { AlignmentType, Footer } from 'docx';
import i18n from 'i18next';
import { elsterDocxUtils } from '../utils/elster_docx_utils';
import { dataUriToBlob } from '@rd-web-markets/shared/dist/util/helpers/dataUrlToBlob';
import logoNoText from '@rd-web-markets/shared/dist/assets/images/ayming-logo-without-text.png';
const generateFootersSetting = () => {
  const {
    t
  } = i18n;
  const logoIcon = dataUriToBlob(logoNoText);
  const {
    createParagraph,
    createTextRun,
    createImageRun
  } = elsterDocxUtils;
  const settings = {
    default: new Footer({
      children: [createParagraph([createTextRun(t('this_document_is_the_property_of_ayming'), {
        size: 14,
        font: 'Georgia',
        color: '666E7E'
      })]), createParagraph([createImageRun(logoIcon, {
        width: 40,
        height: 38
      })], {
        alignment: AlignmentType.END
      })]
    }),
    first: new Footer({
      children: []
    })
  };
  return settings;
};
export default generateFootersSetting;