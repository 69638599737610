import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback } from 'react';
const api = buildService('/elster_data_exports', {
  only: ['get']
});
const elsterDataService = {
  useFetchElsterData: claimGroupId => {
    const getElsterData = useCallback(async () => {
      const data = await api.get(claimGroupId);
      return data;
    }, [claimGroupId]);
    return [getElsterData];
  }
};
elsterDataService.useCrud = claimGroupId => {
  const [getElsterData] = elsterDataService.useFetchElsterData(claimGroupId);
  return [getElsterData];
};
export default elsterDataService;