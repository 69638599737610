import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { APPLICATION_ALLOWANCE_TABLE_INSTRUCTIONS, APPLICATION_ALLOWANCE_TABLE_KEYS, APPLICATION_FOR_RESEARCH_BODY, APPLICATION_FOR_RESEARCH_TITLE } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
const applicationAllowance = data => {
  const {
    t
  } = i18n;
  const {
    createParagraph
  } = elsterDocxUtils;
  return [createParagraph(t(APPLICATION_FOR_RESEARCH_TITLE), {
    style: 'mainText'
  }), createParagraph(t(APPLICATION_FOR_RESEARCH_BODY), {
    style: 'mainText'
  }), emptyLine, generalTable(data, APPLICATION_ALLOWANCE_TABLE_INSTRUCTIONS), emptyLine];
};
export default applicationAllowance;