import { Packer, Paragraph, TextRun, TableCell, WidthType, ImageRun, PageBreak, Bookmark } from 'docx';
import { saveAs } from 'file-saver';
export * from 'docx';
export const elsterDocxUtils = {
  createDocument(name, documenObject) {
    Packer.toBlob(documenObject).then(blob => {
      saveAs(blob, "".concat(name, ".docx"));
    });
  },
  // Helper function to create a styled text run
  createTextRun(children, styles) {
    return new TextRun({
      ...styles,
      children
    });
  },
  // Helper function to create a styled paragraph
  createParagraph(content) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const paragraph = {
      ...options
    };
    if (Array.isArray(content)) {
      paragraph.children = [...content];
    } else {
      paragraph.text = content;
    }
    return new Paragraph({
      ...paragraph
    });
  },
  createTextRunInParagraph: (txt, textRunStyles, paragraphOptions) => {
    const textRun = elsterDocxUtils.createTextRun(txt, textRunStyles);
    const paragraphWithTextRun = elsterDocxUtils.createParagraph([textRun], paragraphOptions);
    return paragraphWithTextRun;
  },
  createBookmark: (text, bookmarkId, textStyle, paragraphOptions) => {
    const paragraph = {
      ...paragraphOptions
    };
    paragraph.children = [new Bookmark({
      id: bookmarkId,
      children: [elsterDocxUtils.createTextRun(text, textStyle)]
    })];
    return new Paragraph({
      ...paragraph
    });
  },
  // Helper function to create a table cell with common styles
  createTableCell(options, width, children) {
    return new TableCell({
      ...options,
      width: {
        size: width,
        type: WidthType.DXA
      },
      children
    });
  },
  // Helper function to create an ImageRun
  createImageRun(imageData, transformation) {
    const imageObj = {
      data: imageData,
      transformation: {
        width: transformation.width,
        height: transformation.height
      }
    };
    if (transformation.horizontalPosition) {
      imageObj.floating = {
        horizontalPosition: {
          offset: transformation.horizontalPosition.offset
        },
        verticalPosition: {
          offset: transformation.verticalPosition.offset
        }
      };
    }
    return new ImageRun({
      ...imageObj
    });
  }
};
export const emptyLine = elsterDocxUtils.createParagraph(['']);
export const pageBreak = elsterDocxUtils.createParagraph([new PageBreak()]);