import { Header, PageNumber } from 'docx';
import { AlignmentType } from 'docx';
import i18n from 'i18next';
import { elsterDocxUtils } from '../utils/elster_docx_utils';
import { dataUriToBlob } from '@rd-web-markets/shared/dist/util/helpers/dataUrlToBlob';
import headerImage from '@rd-web-markets/shared/dist/assets/images/header_image.png';
import headerNumberImage from '@rd-web-markets/shared/dist/assets/images/header_numbering_image.png';
const generateHeadersSetting = () => {
  const {
    t
  } = i18n;
  const headerImageBlob = dataUriToBlob(headerImage);
  const headerNumberImageBlob = dataUriToBlob(headerNumberImage);
  const {
    createParagraph,
    createTextRun,
    createImageRun
  } = elsterDocxUtils;
  const setting = {
    default: new Header({
      children: [createParagraph([createTextRun(t('research_allowance_project_profile'), {
        size: 20,
        font: 'Georgia',
        color: '666E7E'
      })]), createParagraph([createTextRun([PageNumber.CURRENT], {
        bold: true
      })], {
        alignment: AlignmentType.END,
        indent: {
          right: 200
        }
      }), createParagraph([createImageRun(headerNumberImageBlob, {
        width: 665,
        height: 40,
        horizontalPosition: {
          offset: 714400
        },
        verticalPosition: {
          offset: 484400
        }
      })], {
        spacing: {
          after: 250
        }
      })]
    }),
    first: new Header({
      children: [createParagraph([createImageRun(headerImageBlob, {
        width: 650,
        height: 15
      })])]
    })
  };
  return setting;
};
export default generateHeadersSetting;