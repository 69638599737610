import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
const beforeTableOfContent = data => {
  const {
    t
  } = i18n;
  const {
    createParagraph,
    createTextRun
  } = elsterDocxUtils;
  return [createParagraph([createTextRun(t('transmission_protocol'), {
    font: 'Georgia',
    size: 56,
    color: '00AEEF'
  })], {
    spacing: {
      before: 350
    }
  }), createParagraph("".concat(data.company_name, " & ").concat(data.claim_group_financial_year), {
    style: 'mainText'
  }), emptyLine, emptyLine, emptyLine];
};
export default beforeTableOfContent;