import NumberFormat from 'react-number-format';
const generateDocumentProperties = () => {
  const properties = {
    titlePage: true,
    page: {
      margin: {
        top: 780,
        right: 820,
        bottom: 780,
        left: 1170
      },
      pageNumbers: {
        start: 1,
        formatType: NumberFormat.DECIMAL
      }
    }
  };
  return properties;
};
export default generateDocumentProperties;